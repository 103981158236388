<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>過濾器</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>客戶</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="clientFilter"
              :filter="clientSearch"
              :options="clientOptions"
              label="client_code"
              class="w-100"
              :reduce="(val) => val.id"
              @input="(val) => $emit('update:clientFilter', val)"
            >
            <template #option="{client_code, company_name_cn}">
              [{{ client_code }}] {{ company_name_cn }}
            </template>
             <template #selected-option="{client_code, company_name_cn}">
              [{{ client_code }}] {{ company_name_cn }}
            </template>
            </v-select>
          </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>銷售顧問</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userOptions"
            label="name"
            class="w-100"
            :reduce="(val) => val.id"
            @input="(val) => $emit('update:userFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>集團</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="groupcodeFilter"
            :options="groupcodeOptions"
            label="name_of_group"
            class="w-100"
            :reduce="(val) => val.id"
            @input="(val) => $emit('update:groupcodeFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label></label>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="reset"
              style="height: 40px"
            >
              重設
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  methods: {
    reset() {
      this.$emit("update:userFilter", null);
      this.$emit("update:clientFilter", null);
      this.$emit("update:groupcodeFilter", null);
    },
    clientSearch(options, search){
        var returnOptions = options.filter(item => {
          return item.client_code.toLowerCase().includes(search.toLowerCase()) || item.company_name_cn.toLowerCase().includes(search.toLowerCase())
        })
          return returnOptions
      }
  },
  props: {
    userFilter: {
      type: [Number, null],
      default: null,
    },
    clientFilter: {
      type: [Number, null],
      default: null,
    },
    groupcodeFilter: {
      type: [Number, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    groupcodeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      deleteOptions: [
        {
          label: "已刪除",
          value: 1,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
