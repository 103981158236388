<template>
  <div>
    <client-list-filter
      :user-filter.sync="userFilter"
      :user-options="userOptions"
      :client-options="clientOptions"
      :groupcode-options="groupcodeOptions"
      :client-filter.sync="clientFilter"
      :groupcode-filter.sync="groupcodeFilter"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" class="mb-2" style="text-align: center">
            <h3 class="mb-0">客戶資料報表</h3>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            v-if="false"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="exportReport">
                <span class="text-nowrap">導出Excel</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refClientListTable"
        class="position-relative"
        :items="fetchClients"
        responsive
        :fields="tableReportColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Client Code -->
        <template #cell(client_code)="data">
          <b-link
            :to="{ name: 'clients-detail', params: { id: data.item.id } }"
            >{{ data.item.client_code }}</b-link
          >
        </template>
      </b-table>
      <div class="mx-2 mb-2" v-if="false">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalClients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import clientStoreModule from "@/views/client/client/clientStoreModule";
import useClientsList from "@/views/client/client/clients-list/useClientsList";
import ClientListFilter from "@/views/client/client/clients-list/ClientListFilter.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ClientListFilter,
  },
  methods: {
    exportReport() {
      this.$swal({
        title: "確定導出Excel?",
        showCancelButton: true,
        confirmButtonText: "下載",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("app-client/exportClients", {
              perPage: this.perPage,
              page: this.currentPage,
              sortBy: this.sortBy,
              sortDesc: this.isSortDirDesc,
              sales_id: this.userFilter,
              client_id: this.clientFilter,
              group_code_id: this.groupcodeFilter,
            })
            .then((response) => {
              // create file link in browser's memory
              const href = URL.createObjectURL(response.data);
              // create "a" HTLM element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              const current_datetime = new Date();
              const formatted_date =
                current_datetime.getFullYear() +
                "-" +
                (current_datetime.getMonth() + 1) +
                "-" +
                current_datetime.getDate() +
                " " +
                current_datetime.getHours() +
                "-" +
                current_datetime.getMinutes() +
                "-" +
                current_datetime.getSeconds();
              link.setAttribute(
                "download",
                "Clients" + formatted_date + ".xlsx"
              );
              document.body.appendChild(link);
              link.click();

              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-client";
    const loading = ref(false);

    const isAddNewClientSidebarActive = ref(false);

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, clientStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
        store.unregisterModule(APP_CLIENT_STORE_MODULE_NAME);
    });

    onMounted(() => {
      currentPage.value = 1;
      perPage.value = null;
    });

    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refClientListTable,
      ability,
      userFilter,
      clientFilter,
      groupcodeFilter,
      tableReportColumns,
    } = useClientsList();

    //Filter
    const userOptions = ref([]);
    const clientOptions = ref([]);
    const groupcodeOptions = ref([]);

    store
      .dispatch("app/fetchOptionList", {
        client: true,
        group_code: true,
        user: true,
      })
      .then((response) => {
        clientOptions.value = response.data.clients;
        groupcodeOptions.value = response.data.group_codes;
        userOptions.value = response.data.users;
      })
      .catch((error) => {
        console.log(error);
      });

    return {
      isAddNewClientSidebarActive,
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refClientListTable,
      ability,
      clientOptions,
      userOptions,
      groupcodeOptions,
      userFilter,
      clientFilter,
      groupcodeFilter,
      tableReportColumns,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
