import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    exportClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/reports/clients/export", {
            params: queryParams,
            responseType: "blob",
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientMeetings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client/meetings`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client/optionList`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/client", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/client", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // setting query
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/user/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchServiceCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/service_code/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchApplicationCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/application_code/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/project/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/project", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/project/updateApplicationRemark", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/invoice/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchQuotations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/quotation/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContracts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addInvoicePayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/invoice/payment", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchInvoicePayment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/paymentList", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/contract", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    signContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/contract/sign", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchGroupCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/group_code/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadContract(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/contract/export", {
            params: queryParams,
            responseType: "blob",
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadInvoice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/invoice/export", { params: queryParams, responseType: "blob" })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
