import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useClientsList() {

    //User toast
    const toast = useToast()

    const refClientListTable = ref(null)

    const tableColumns = [
        {key: 'client_code', label:'客戶編號', sortable: true},
        {key: 'group_code.name_of_group', label:'集團'},
        {key: 'company_name_cn', label:'公司名稱(中文)', sortable: true},
        {key: 'company_name_en', label:'公司名稱(英文)', sortable: true},
        {key: 'sale.name', label:'銷售顧問', sortable: true},
        /* {key: 'contact_name', label:'CONTACT PERSON', sortable: true},
        {key: 'contact_phone', label:'CONTACT PHONE', sortable: true}, */
        {key: 'customer_type', label:'行業', sortable: true},
        {key: 'br_no', label:'公司商業登記號碼', sortable: true},
       /*  {key: 'br_address', label:'BR ADDRESS', sortable: true}, */
        /* {key: 'remark', label:'備註', sortable: true}, */
        {key: 'actions', label: '操作'},
    ]

    const tableReportColumns = [
        {key: 'client_code', label:'客戶編號', sortable: true},
        {key: 'group_code.name_of_group', label:'集團'},
        {key: 'company_name_cn', label:'公司名稱(中文)', sortable: true},
        {key: 'company_name_en', label:'公司名稱(英文)', sortable: true},
        {key: 'sale.name', label:'銷售顧問', sortable: true},
        {key: 'customer_type', label:'行業', sortable: true},
    ];

    //Filter
    const userFilter = ref(null)
    const clientFilter = ref(null)
    const groupcodeFilter = ref(null)

    const perPage = ref(10)
    const totalClients = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refClientListTable.value ? refClientListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalClients.value,
        }
    })

    const refetchData = () => {
        refClientListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, userFilter, clientFilter, groupcodeFilter], () => {
        refetchData()
    })

    const fetchClients = (ctx, callback) => {
        store.dispatch('app-client/fetchClients',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            sales_id: userFilter.value,
            client_id: clientFilter.value,
            group_code_id: groupcodeFilter.value,
        })
        .then(response =>{
            const {clients, total} = response.data
            callback(clients)
            totalClients.value = total
        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching client list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchClients,
        tableColumns,
        perPage,
        currentPage,
        totalClients,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refClientListTable,
        ability,
        refetchData,
        userFilter,
        clientFilter,
        groupcodeFilter,
        tableReportColumns
    }

}